export const environment = {
  production: false,
  clientName: 'siesa',
  apiGatewayFront: 'https://ecommerceagrtest.plexa.co/',
  apiGatewayBackOffice: 'https://ecommerceagrtest.plexa.co/',
  apiUrl: 'https://ecommerceagrtest.plexa.co/',
  urlPath: 'https://ecommerceagrtest.plexa.co/',
  customCss: false,
  // Whether or not to enable debug mode
  // Setting this to false will disable console output
  enableDebug: false,
  siteKeyCaptcha: '6LdAYWMpAAAAALMPDKH58MXleYGaVWyuUy3tzSg_',
  googleAnalyticsCode: 'UA-140785247-1',

 

};


